import React from "react"
import Cropper from "../thirdparty/react-easy-crop/index"
import Resizer from "react-image-file-resizer"
import { withRouter } from "react-router-dom"
import { v4 } from "uuid"
import {
  Button,
  message,
  Steps,
  Image as AntdImage,
  Row,
  Col,
  Alert,
} from "antd"
import {
  LoadingOutlined,
  InfoCircleTwoTone,
  PlayCircleOutlined,
  CameraOutlined,
  CloudDownloadOutlined,
} from "@ant-design/icons"

const getRadianAngle = (degreeValue) => {
  return (degreeValue * Math.PI) / 180
}

const { Step } = Steps

const PHOTOBOOTH_API = `${process.env.REACT_APP_SERVER_URL}/processPhotoBooth`
const GET_PHOTOBOOTH_API = `${process.env.REACT_APP_SERVER_URL}/getPhotoBooth`
const RECAPTCHA_URL = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`
const SIGNED_URL_API = `${process.env.REACT_APP_SERVER_URL}/getProfilePhotoSignedURL`
const RECAPTCHA_KEY = "recaptcha-key"

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener("load", () => resolve(image))
    image.addEventListener("error", (error) => reject(error))
    image.setAttribute("crossOrigin", "anonymous") // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })

const resizeFile = (file, maxWidth, maxHeight) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri)
      },
      "base64"
    )
  })

const getCroppedImage = async (imageSrc, croppedArea, rotation) => {
  try {
    const image = await createImage(imageSrc)
    const canvas = document.createElement("canvas")
    const ctx = canvas.getContext("2d")
    console.log("Image W:", image.width, "H:", image.height)
    const maxSize = Math.max(image.width, image.height)
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

    console.log(
      "Safe Area:",
      safeArea,
      "Img w:",
      image.width,
      "h:",
      image.height
    )
    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea
    canvas.height = safeArea

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2)
    ctx.rotate(getRadianAngle(rotation))
    ctx.translate(-safeArea / 2, -safeArea / 2)

    // draw rotated image and store data.
    ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5
    )
    const data = ctx.getImageData(0, 0, safeArea, safeArea)

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = croppedArea.width
    canvas.height = croppedArea.height

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
      data,
      Math.round(0 - safeArea / 2 + image.width * 0.5 - croppedArea.x),
      Math.round(0 - safeArea / 2 + image.height * 0.5 - croppedArea.y)
    )

    return new Promise((resolve) => {
      canvas.toBlob((file) => {
        resolve(file)
      }, "image/jpeg")
    })
  } catch (error) {
    console.log(error)
    return new Promise((resolve, reject) => {
      reject(error)
    })
  }
}
class PhotoBooth extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      aspect: parseFloat(process.env.REACT_APP_PHOTOBOOTH_ASPECT_RATIO),
      attId: this.props.match.params.attId,
      eventId: this.props.match.params.eventId,
      cropContainerHeight: null,
      photoboothId: this.props.match.params.id,
      file_list: [],
      zoom: 1,
      crop: {
        x: 0,
        y: 0,
      },
      croppedArea: null,
      rotation: 0,
      photoBoothUrl: null,
      src: null,
      recaptchaLoaded: false,
      templateImage: null,
      buttonLoading: false,
      currentState: 0,
      photo: null,
      error: null,
    }
    this.fileInputRef = React.createRef()
  }

  componentDidMount = () => {
    if (!document.getElementById(RECAPTCHA_KEY)) {
      var script = document.createElement("script")
      script.type = "text/javascript"
      script.src = RECAPTCHA_URL
      script.id = RECAPTCHA_KEY
      script.onload = this.recaptchaScriptLoaded
      document.body.appendChild(script)
    } else {
      this.setState({ recaptchaLoaded: true })
    }
    window.addEventListener("resize", this.onWindowResized)
    this.loadPhotoBooth()
  }

  loadPhotoBooth = async () => {
    try {
      const response = await fetch(
        `${GET_PHOTOBOOTH_API}?eid=${this.state.eventId}&pid=${this.state.photoboothId}`,
        {
          method: "GET",
          headers: {
            authorization: `Bearer ${process.env.REACT_APP_SERVER_API_KEY}`,
          },
        }
      )
      const jsonResp = await response.json()
      if (jsonResp.result) {
        this.setState({
          photo: jsonResp.photo,
          aspect: parseFloat(jsonResp.photo.aspect),
        })
        this.loadTemplateImage(jsonResp.photo)
      } else {
        this.setState({ error: jsonResp.error })
      }
    } catch (error) {
      message.error(error, process.env.REACT_APP_MESSAGE_ERROR_TIMEOUT)
      return []
    }
  }

  updateCropContainerHeight = () => {
    const cropContainer = document.getElementById("crop-container")
    if (cropContainer) {
      const height = cropContainer.clientWidth / this.state.aspect
      this.setState({ cropContainerHeight: height })
    }
  }

  componentDidUpdate = () => {
    if (!this.state.cropContainerHeight) {
      this.updateCropContainerHeight()
    }
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.onWindowResized)
  }

  onWindowResized = () => {
    this.updateCropContainerHeight()
  }

  recaptchaScriptLoaded = () => {
    this.setState({ recaptchaLoaded: true })
  }

  process = async () => {
    this.setState({ buttonLoading: true })

    try {
      const image = await getCroppedImage(
        this.state.src,
        this.state.croppedArea,
        this.state.rotation
      )
      const fileName = `${this.state.attId}.jpeg`
      const fileToUpload = new File([image], fileName, {
        lastModified: new Date().getTime(),
        type: image.type,
      })
      const filePath = `${this.state.eventId}/photobooth/${this.state.photoboothId}/${v4()}_${fileName}`
      await this.uploadUserPhoto(filePath, fileToUpload)
      const photoUrl = await this.processPhotoBooth(filePath)
      this.setState({ photoBoothUrl: photoUrl, currentState: 2 })
    } catch (error) {
      console.log(error)
      message.error(error)
      this.setState({ buttonLoading: false })
    }
  }

  processPhotoBooth = async (user_filepath) => {
    const token = await window.grecaptcha.execute(
      process.env.REACT_APP_RECAPTCHA_SITE_KEY,
      { action: "submit" }
    )

    if (!token || token === "") {
      throw Error(
        "Failed to verify recaptcha to process PhotoBooth, please retry."
      )
    }

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${process.env.REACT_APP_SERVER_API_KEY}`,
      },
      body: JSON.stringify({
        token,
        user_filepath,
        template_url: this.state.photo.src_m,
        eventId: this.state.eventId,
        photoboothId: this.state.photoboothId,
        attId: this.state.attId,
      }),
    }

    try {
      const response = await fetch(PHOTOBOOTH_API, requestOptions)
      const jsonResp = await response.json()
      if (jsonResp.result) {
        return jsonResp.photoboothUrl
      } else {
        throw jsonResp.error
      }
    } catch (error) {
      throw error
    }
  }

  loadTemplateImage = async (photo) => {
    try {
      const response = await fetch(photo.src_s)
      const templateImageBlob = await response.blob()
      const dataUrl = URL.createObjectURL(templateImageBlob)
      this.setState({
        templateImage: dataUrl,
      })
    } catch (error) {
      console.log(error)
      message.error("Error loading template")
    }
  }

  uploadUserPhoto = async (filePath, image) => {
    const token = await window.grecaptcha.execute(
      process.env.REACT_APP_RECAPTCHA_SITE_KEY,
      { action: "submit" }
    )

    if (!token || token === "") {
      throw Error(
        "Failed to verify recaptcha to upload user's photo, please retry."
      )
    }

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/octet-stream",
      },
      body: image,
    }
    try {
      const signedUrlResp = await fetch(
        `${SIGNED_URL_API}?filePath=${encodeURI(filePath)}&t=${token}`,
        {
          method: "GET",
          headers: {
            authorization: `Bearer ${process.env.REACT_APP_SERVER_API_KEY}`,
          },
        }
      )
      const signedUrlJsonResp = await signedUrlResp.json()
      if (signedUrlJsonResp.result) {
        await fetch(signedUrlJsonResp.url, requestOptions)
      } else {
        throw signedUrlJsonResp.error
      }
    } catch (error) {
      throw error
    }
  }

  processInputFile = async (file) => {
    try {
      // Resizing file now, to avoid iOS black canvas issue
      // TODO: set constant here, hardcoded now
      const resizedBlob = await resizeFile(file, 1800, 1200)
      this.setState({ src: resizedBlob, currentState: 1 })
    } catch (error) {
      console.log(error)
    }
  }

  showStep1 = () => {
    return (
      <div style={{ marginTop: 30 }}>
        {!this.state.templateImage ? (
          <div style={{ textAlign: "center" }}>
            <img src='/process_loader.gif' alt='Loading' />
          </div>
        ) : (
          <div>
            <div className='info-container'>
              <Row wrap={false}>
                <Col flex='40px'>
                  <InfoCircleTwoTone style={{ fontSize: 24 }} />
                </Col>
                <Col flex='auto'>
                  <p>
                    Pilih foto anda untuk dimasukkan ke foto template di bawah
                    ini.
                  </p>
                </Col>
              </Row>
            </div>
            <AntdImage src={this.state.templateImage} preview={false} />
            <input
              ref={this.fileInputRef}
              type='file'
              style={{ display: "none" }}
              onChange={(e) => {
                const file = e.target.files[0]
                if (!file.type.startsWith("image/")) {
                  message.error(`${file.name} is not an image file`)
                  return
                }
                this.processInputFile(file)
                this.setState({
                  file_list: [file],
                })
              }}
            />
            <div style={{ marginTop: 15 }}>
              <Button
                style={{ width: "100%" }}
                type='primary'
                loading={this.state.buttonLoading}
                icon={<CameraOutlined />}
                onClick={() => {
                  this.fileInputRef.current.click()
                }}
              >
                Pilih Foto
              </Button>
            </div>
          </div>
        )}
      </div>
    )
  }

  showStep2 = () => {
    return (
      <div style={{ marginTop: 30 }}>
        <div className='info-container'>
          <Row wrap={false}>
            <Col flex='35px'>
              <InfoCircleTwoTone style={{ fontSize: 24 }} />
            </Col>
            <Col flex='auto'>
              <p>
                Silahkan membuat pengaturan dengan menggeser ataupun mencubit
                foto yang telah anda pilih.
              </p>
            </Col>
          </Row>
        </div>
        {this.state.src && (
          <div
            id='crop-container'
            className='crop-container'
            style={{ height: this.state.cropContainerHeight }}
          >
            <Cropper
              showGrid={false}
              image={this.state.src}
              crop={this.state.crop}
              zoom={this.state.zoom}
              aspect={this.state.aspect}
              minZoom={0.1}
              restrictPosition={false}
              templateSrc={this.state.templateImage}
              rotation={this.state.rotation}
              onRotationChange={(val) => this.setState({ rotation: val })}
              onCropChange={(val) => this.setState({ crop: val })}
              onCropComplete={(percentage, pixels) => {
                this.setState({ croppedArea: pixels })
              }}
              onZoomChange={(val) => this.setState({ zoom: val })}
            />
            {this.state.buttonLoading && (
              <div className='loader-container'>
                <Row justify='center' align='middle' style={{ height: "100%" }}>
                  <Col>
                    <img src='/process_loader.gif' alt='Loading' />
                  </Col>
                  <Col>
                    <img src='/process_loader_2.gif' alt='Loading' />
                  </Col>
                </Row>
              </div>
            )}
          </div>
        )}
        {this.state.file_list.length === 1 && (
          <div style={{ marginTop: 15 }}>
            <Button
              onClick={this.process}
              style={{ width: "100%" }}
              type='primary'
              loading={this.state.buttonLoading}
              icon={<PlayCircleOutlined />}
            >
              Process Image
            </Button>
          </div>
        )}
      </div>
    )
  }

  showStep3 = () => {
    return (
      <div style={{ marginTop: 30 }}>
        <div className='info-container'>
          <Row wrap={false}>
            <Col flex='35px'>
              <InfoCircleTwoTone style={{ fontSize: 24 }} />
            </Col>
            <Col flex='auto'>
              <p>
                Silahkan unduh foto kamu untuk disimpan, diprint, atau dibagikan
                di social media.
              </p>
            </Col>
          </Row>
        </div>
        {this.state.photoBoothUrl && (
          <div>
            <AntdImage src={this.state.photoBoothUrl} preview={false} />
          </div>
        )}
        <div style={{ marginTop: 15 }}>
          <a href={this.state.photoBoothUrl} download>
            <Button
              onClick={this.process}
              style={{ width: "100%" }}
              type='primary'
              icon={<CloudDownloadOutlined />}
            >
              Unduh
            </Button>
          </a>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className='site-layout-content'>
        {this.state.error ? (
          <Alert message={this.state.error} type='error' />
        ) : (
          <div>
            <Steps size='small' current={this.state.currentState}>
              <Step title='Upload' />
              <Step
                title='Adjust'
                icon={
                  this.state.buttonLoading && this.state.currentState === 1 ? (
                    <LoadingOutlined />
                  ) : null
                }
              />
              <Step title='Share' />
            </Steps>
            {this.state.currentState === 0 && <this.showStep1 />}
            {this.state.currentState === 1 && <this.showStep2 />}
            {this.state.currentState === 2 && <this.showStep3 />}
          </div>
        )}
      </div>
    )
  }
}

export default withRouter(PhotoBooth)
