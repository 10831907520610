import React from "react"
import { Layout, Image, Row, Col } from "antd"
import Default from "./components/Default"
import "./App.less"
import { Route, Switch } from "react-router"
import SocialMedia from "./components/SocialMedia"
import PhotoBooth from "./components/PhotoBooth"
import PhotoGallery from "./components/PhotoGallery"

const { Header, Footer, Content } = Layout

const App = () => (
    <div className="App">
        <Layout className="layout">
            <Header>
                <div className="logo" >
                    <Row>
                        <Col>
                            <Image
                                width={64}
                                preview={false}
                                src="https://storage.googleapis.com/gloria_assets_id/reg/logo192.png" />
                        </Col>
                        <Col>
                            <div className="main-color"
                                style={{
                                    fontSize: 30,
                                    fontWeight: 800,
                                    marginLeft: 5
                                }}>
                                GKA GLORIA
                                </div>
                        </Col>
                    </Row>
                </div>
            </Header>
            <Content style={{ padding: "0 50px" }}>
                <Switch>
                    <Route path="/photobooth/:eventId/:attId/:id">
                        <PhotoBooth />
                    </Route>
                    <Route path="/photobooth/:eventId/:attId">
                        <PhotoGallery />
                    </Route>
                    <Route path="/">
                        <Default />
                    </Route>
                </Switch>
            </Content>
            <Footer style={{ textAlign: "center" }}>
                <SocialMedia />
                GKA Gloria Kota Satelit ©2021
            </Footer>
        </Layout>
    </div>
)

export default App
