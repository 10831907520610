import React from "react"
import { Divider } from "antd"

const SocialMedia = (props) => {
    return (
        <div style={{paddingBottom: 10}}>
            <a
                href='https://www.youtube.com/gloriasatelit'
                style={{textDecoration: "none"}}
            >
                <img
                    src='https://storage.googleapis.com/gloria_assets_id/reg/youtube.png'
                    width={24}
                    alt='f'
                    style={{ display: "inline-block", color: "#cccccc" }}
                />
            </a>
            <Divider type="vertical"/>
            <a
                href='https://www.instagram.com/gloriasundayschool'
                style={{textDecoration: "none"}}
            >
                <img
                    src='https://storage.googleapis.com/gloria_assets_id/reg/instagram.png'
                    width={24}
                    alt='i'
                    style={{ display: "inline-block", color: "#cccccc" }}
                />
            </a>
            <Divider type="vertical"/>
            <a
                href='https://wa.me/083830451958'
                style={{textDecoration: "none"}}
            >
                <img
                    src='https://storage.googleapis.com/gloria_assets_id/reg/whatsapp.png'
                    width={24}
                    alt='w'
                    style={{ display: "inline-block", color: "#cccccc" }}
                />
            </a>
            <Divider type="vertical"/>
            <a
                href='mailto:anak@gloriasatelit.org'
                style={{textDecoration: "none"}}
            >
                <img
                    src='https://storage.googleapis.com/gloria_assets_id/reg/email.png'
                    width={24}
                    alt='e'
                    style={{ display: "inline-block", color: "#cccccc" }}
                />
            </a>
        </div>
    )
}

export default SocialMedia
