import React from "react"
import { withRouter } from "react-router-dom"
import {
    Typography,
    Row,
    Col,
    Image,
    List,
    message,
    Card,
    Divider,
    Alert,
    Skeleton,
} from "antd"
import { ClockCircleTwoTone, CalendarTwoTone } from "@ant-design/icons"
import DOMPurify from "dompurify"
import {
    isSameDay,
    fromUnixTime,
    format,
    differenceInCalendarDays,
} from "date-fns"
const { Title, Text } = Typography
const { Meta } = Card
const GET_EVENT_API = `${process.env.REACT_APP_SERVER_URL}/getEventDetail`
const GET_PHOTOBOOTH_API = `${process.env.REACT_APP_SERVER_URL}/getPhotoBooths`
const RECAPTCHA_KEY = "recaptcha-key"
const RECAPTCHA_URL = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`

class PhotoGallery extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            attId: this.props.match.params.attId,
            eventId: this.props.match.params.eventId,
            dataLoaded: false,
            event: null,
            attendance: null,
            photos: null,
            error: null,
        }
    }

    componentDidMount = () => {
        if (!document.getElementById(RECAPTCHA_KEY)) {
            var script = document.createElement("script")
            script.type = "text/javascript"
            script.src = RECAPTCHA_URL
            script.id = RECAPTCHA_KEY
            script.onload = this.recaptchaScriptLoaded
            document.body.appendChild(script)
        } else {
            this.setState({ recaptchaLoaded: true })
        }

        this.initialDataLoad()
    }

    initialDataLoad = async () => {
        try {
            const data = await this.loadEvent()
            if (!data.event || !data.attendance) {
                this.setState({
                    error: "Please use the PhotoBooth URL obtained from your registration email.",
                })
                return
            }
            this.setState({
                event: data.event,
                attendance: data.attendance,
            })

            const photos = await this.loadPhotoBooth()
            this.setState({
                photos: photos,
                dataLoaded: true,
            })
            if (data.event) document.title = data.event.name
        } catch (error) {
            message.error(error, process.env.REACT_APP_MESSAGE_ERROR_TIMEOUT)
        }
    }

    loadEvent = async () => {
        try {
            const response = await fetch(
                `${GET_EVENT_API}?eid=${this.state.eventId}&aid=${this.state.attId}`,
                {
                    method: "GET",
                    headers: {
                        authorization: `Bearer ${process.env.REACT_APP_SERVER_API_KEY}`,
                    },
                }
            )
            const jsonResp = await response.json()

            return {
                event: jsonResp.event,
                attendance: jsonResp.attendance,
            }
        } catch (error) {
            throw error
        }
    }

    loadPhotoBooth = async () => {
        try {
            const response = await fetch(
                `${GET_PHOTOBOOTH_API}?eid=${this.state.eventId}`,
                {
                    method: "GET",
                    headers: {
                        authorization: `Bearer ${process.env.REACT_APP_SERVER_API_KEY}`,
                    },
                }
            )
            const jsonResp = await response.json()

            if (jsonResp.result) {
                return jsonResp.photos
            } else {
                return []
            }
        } catch (error) {
            message.error(error, process.env.REACT_APP_MESSAGE_ERROR_TIMEOUT)
            return []
        }
    }

    PhotoCards = () => {
        return (
            <div>
                {!this.state.photos ? (
                    <Row gutter={[5, 0]}>
                        <Col flex='auto' style={{ textAlign: "center" }}>
                            <Skeleton.Image />
                        </Col>
                        <Col flex='auto' style={{ textAlign: "center" }}>
                            <Skeleton.Image />
                        </Col>
                        <Col flex='auto' style={{ textAlign: "center" }}>
                            <Skeleton.Image />
                        </Col>
                    </Row>
                ) : (
                    <List
                        grid={{
                            gutter: 16,
                            xs: 1,
                            sm: 2,
                            md: 3,
                            lg: 3,
                            xl: 3,
                            xxl: 3,
                        }}
                        dataSource={this.state.photos}
                        renderItem={(photo) => (
                            <List.Item key={photo.id}>
                                <button
                                    style={{ border: 0, padding: 0 }}
                                    onClick={() => {
                                        if (
                                            isSameDay(
                                                new Date(),
                                                fromUnixTime(
                                                    photo.available_date
                                                        ._seconds
                                                )
                                            )
                                        ) {
                                            this.props.history.push(
                                                `/photobooth/${this.state.eventId}/${this.state.attId}/${photo.id}`
                                            )
                                        } else {
                                            message.error(
                                                "The photo template will be available on " +
                                                    format(
                                                        fromUnixTime(
                                                            photo.available_date
                                                                ._seconds
                                                        ),
                                                        "d MMMM yyyy"
                                                    ) +
                                                    "."
                                            )
                                        }
                                    }}
                                >
                                    <Card
                                        hoverable={isSameDay(
                                            new Date(),
                                            fromUnixTime(
                                                photo.available_date._seconds
                                            )
                                        )}
                                        cover={
                                            <Image
                                                preview={false}
                                                src={photo.thumbnail}
                                            />
                                        }
                                    >
                                        <Meta
                                            title={photo.name}
                                            description={
                                                <div>
                                                    <ClockCircleTwoTone
                                                        twoToneColor={
                                                            isSameDay(
                                                                new Date(),
                                                                fromUnixTime(
                                                                    photo
                                                                        .available_date
                                                                        ._seconds
                                                                )
                                                            )
                                                                ? "#52c41a"
                                                                : "#eb2f96"
                                                        }
                                                    />{" "}
                                                    {format(
                                                        fromUnixTime(
                                                            photo.available_date
                                                                ._seconds
                                                        ),
                                                        "d MMMM yyyy"
                                                    )}
                                                </div>
                                            }
                                        />
                                    </Card>
                                </button>
                            </List.Item>
                        )}
                    />
                )}
            </div>
        )
    }

    EventContainer = () => {
        return (
            <div>
                <div>
                    {this.state.attendance && (
                        <Title
                            level={2}
                            style={{ fontWeight: 700 }}
                            className='main-color'
                        >
                            {/* Greetings, {this.state.attendance.name}! */}
                            Greetings!
                        </Title>
                    )}
                    {!this.state.event ? (
                        <Skeleton active />
                    ) : (
                        <Row gutter={[15, 0]}>
                            <Col md={10} style={{ paddingBottom: 15 }}>
                                <Image
                                    preview={false}
                                    src={this.state.event.img}
                                />
                            </Col>
                            <Col md={14}>
                                <Title
                                    className='main-color'
                                    style={{ fontWeight: 700 }}
                                    level={3}
                                >
                                    {this.state.event.name}
                                </Title>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(
                                            this.state.event.description
                                        ),
                                    }}
                                ></div>
                            </Col>
                        </Row>
                    )}
                    <Divider orientation='left'>
                        <Text
                            className='main-color'
                            style={{
                                fontWeight: 600,
                                fontSize: 20,
                            }}
                        >
                            Event Date
                        </Text>
                    </Divider>
                    {!this.state.event ? (
                        <Skeleton active title={{ width: 0 }} />
                    ) : (
                        <div>
                            <div>
                                <CalendarTwoTone />{" "}
                                <Text>
                                    {differenceInCalendarDays(
                                        fromUnixTime(this.state.event.end_date),
                                        fromUnixTime(
                                            this.state.event.start_date
                                        )
                                    ) > 0 ? (
                                        <Text>
                                            {format(
                                                fromUnixTime(
                                                    this.state.event.start_date
                                                ),
                                                "d MMMM yyyy"
                                            )}
                                            -{" "}
                                            {format(
                                                fromUnixTime(
                                                    this.state.event.end_date
                                                ),
                                                "d MMMM yyyy"
                                            )}
                                        </Text>
                                    ) : (
                                        <Text>
                                            {format(
                                                fromUnixTime(
                                                    this.state.event.start_date
                                                ),
                                                "iiii, d MMMM yyyy"
                                            )}
                                        </Text>
                                    )}
                                </Text>
                            </div>
                            <div>
                                <ClockCircleTwoTone />{" "}
                                <Text>
                                    {format(
                                        fromUnixTime(
                                            this.state.event.start_date
                                        ),
                                        "HH:mm"
                                    )}{" "}
                                    {format(
                                        fromUnixTime(
                                            this.state.event.start_date
                                        ),
                                        "O"
                                    ) === "GMT+7"
                                        ? "WIB"
                                        : format(
                                              fromUnixTime(
                                                  this.state.event.start_date
                                              ),
                                              "O"
                                          )}{" "}
                                </Text>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className='site-layout-content'>
                <Typography>
                    {this.state.error ? (
                        <Alert message={this.state.error} type='error' />
                    ) : (
                        <div>
                            <div>
                                <this.EventContainer />
                                <Divider orientation='left'>
                                    <Text
                                        style={{
                                            fontWeight: 600,
                                            fontSize: 20,
                                        }}
                                        className='main-color'
                                    >
                                        PhotoBooth Campaign
                                    </Text>
                                </Divider>
                                <this.PhotoCards />
                            </div>
                        </div>
                    )}
                </Typography>
            </div>
        )
    }
}

export default withRouter(PhotoGallery)
